import React from 'react';
import styled from 'styled-components';
import sharePlatforms from '../constants/share-platforms';
import ShareIcon from './ShareIcon';

const SocialShare = ({ className, title, path }) => {
  return (
    <ul className={className}>
      {sharePlatforms.map((item, idx) => {
        return (
          <li className="share-item" key={idx}>
            <ShareIcon platform={item.name} title={title} path={path}>
              {item.icon}
            </ShareIcon>
          </li>
        );
      })}
    </ul>
  );
};

export default styled(SocialShare)`
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding-bottom: 1rem;

  .share-item {
    margin: 0 1rem;
    transition: var(--mainTransition);
    opacity: 0.9;
    a {
      color: var(--mainWhite);
      font-size: 1.2rem;
      transition: var(--mainTransition);
    }
    &:hover {
      transform: translateY(-5px);
      a {
        color: var(--primaryColor);
      }
    }
  }
`;
