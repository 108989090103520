import React, { Component } from 'react';

class ShareIcon extends Component {
  state = {
    url: '',
  };

  componentDidMount() {
    this.createShareUrl();
  }

  /**
   * Use title and path props from post frontmatter to generate
   * a unique share url for the specific post on each platform.
   */

  createShareUrl = () => {
    const { platform, title, path } = this.props;

    let url = '';
    switch (platform) {
      case 'twitter':
        url = `https://twitter.com/intent/tweet/?text=${title}&url=https://thecodesmith.co${path}%2F&via=TCodesmith`;
        break;
      case 'reddit':
        url = `http://www.reddit.com/submit/?url=https://thecodesmith.co${path}`;
        break;
      case 'facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=https://thecodesmith.co${path}`;
        break;
      case 'linkedin':
        url = `https://www.linkedin.com/shareArticle?mini=true&url=https://thecodesmith.co${path}&title=${title}&source=${title}`;
        break;
      default:
        break;
    }

    this.setState({ url });
  };

  render() {
    return (
      <a href={this.state.url} target="_blank" rel="noopener noreferrer">
        {this.props.children}
      </a>
    );
  }
}

export default ShareIcon;
