import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Title from '../components/Title';
import SocialShare from '../posts/assets/components/SocialShare';
import ColoredLine from '../components/utils/ColoredLine';

const postTemplate = ({ data, className }) => {
  const { title, updated, date, images, path } = data.mdx.frontmatter;
  const { body } = data.mdx;
  return (
    <Layout>
      <SEO title={title} />
      <section className={className}>
        <Title title={title} padding={'1rem 0'}></Title>
        <SocialShare title={title} path={path}></SocialShare>
        <article className="post">
          <MDXRenderer images={images}>{body}</MDXRenderer>
        </article>
        <div className="published">
          <ColoredLine width={'20%'} margin={'2rem 0 1rem 0'} />
          <div className="pub-dates">
            <p>Published: {date}</p>
            {updated ? <p>Updated: {updated}</p> : null}
          </div>
        </div>
        <div className="revision">
          <AniLink fade to="/contact">
            <button className="btn-white">Suggest An Improvement</button>
          </AniLink>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query getPost($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        path
        slug
        updated(formatString: "MMM Do, YYYY")
        date(formatString: "MMM Do, YYYY")
        author
        images {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      body
    }
  }
`;

export default styled(postTemplate)`
  font-size: 1.15rem;
  line-height: 1.65;
  color: #eee;
  min-height: 20rem;
  background: var(--mainEarth);
  padding: 2rem 0;

  .revision {
    text-align: center;
    background-color: var(--darkestEarth);
    padding: 2rem 0 1rem 0;
    margin-bottom: -2rem;
    margin-top: 4rem;
  }

  .bulletList {
    list-style: none;
    padding-left: 1rem;
    li {
      margin-bottom: 1.25rem;
    }
    li::before {
      content: '▪';
      color: var(--mainYellow);
      margin-right: 1rem;
    }
  }

  .itemList li {
    border-bottom: 3px solid #22222280;
    padding-bottom: 1rem;
    padding-top: 1rem;
    list-style: none;
  }

  .itemList li:last-child {
    border-bottom: none;
    padding-bottom: 2.5rem;
  }

  .codeblock {
    /* used for CodeLabel positioning */
    position: relative;
    background-color: var(--primaryCode);
    padding: 2rem 1rem;
    border-radius: 0.5rem;
    margin: 2rem 0;
    overflow-x: auto;
    font-size: 1rem;
  }

  .postSummary {
    background-color: var(--darkEarth);
    padding: 1.5rem 1rem;
    margin-bottom: 2rem;
    border-left: 0.4rem solid var(--cardEarth);
    text-align: center;

    p {
      margin-bottom: 0;
    }
  }

  .postTitle {
    display: flex;
  }

  .postTitle::after {
    content: ' ';
    height: 3px;
    flex-grow: 1;
    margin: auto 0.5rem;
    background: var(--darkestEarth);
  }

  .post,
  .published {
    max-width: 90%;
    margin: 1rem auto;
  }

  .pub-dates {
    font-size: 1.15rem;
    p {
      margin-bottom: 0.5rem;
    }
  }

  @media (min-width: 576px) {
    .post,
    .published {
      max-width: 80%;
    }

    .summaryWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .postSummary {
      text-align: left;
      padding: 1.5rem;
    }

    .codeblock {
      padding: 2rem;
      font-size: 1.15rem;
    }
  }

  @media (min-width: 768px) {
    .post,
    .published {
      max-width: 75%;
      font-size: 1.35rem;
    }
  }
`;
