import React from 'react';
import {
  FaTwitter,
  FaRedditAlien,
  FaFacebookF,
  FaLinkedin,
} from 'react-icons/fa';

export default [
  {
    name: 'twitter',
    icon: <FaTwitter />,
  },
  {
    name: 'reddit',
    icon: <FaRedditAlien />,
  },
  {
    name: 'facebook',
    icon: <FaFacebookF />,
  },
  {
    name: 'linkedin',
    icon: <FaLinkedin />,
  },
];
